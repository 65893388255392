import { useNotification } from '@/containers'
import type { StaticImageData } from 'next/image'
import { type FC } from 'react'
import { CompareAddIcon } from '../Icon/CompareAddIcon'
import ImageOptimize from '../ImageOptimize'

interface Props {
  imgSrc: StaticImageData | string | undefined
  imgAlt: string
  id: string
  price: string
  name: string
  canCompare?: boolean
  handleAddToCompare?: () => void
}
export const SearchProductItemCard: FC<Props> = ({
  imgSrc = null,
  imgAlt,
  name,
  price,
  canCompare,
  handleAddToCompare,
}) => {
  const { systemConfig } = useNotification()
  return (
    <div className='flex border-b py-[16px] pl-[24px]'>
      <ImageOptimize
        alt={imgAlt}
        src={
          imgSrc ||
          systemConfig?.imagePlaceholder ||
          process.env.NEXT_PUBLIC_IMAGE_PLACEHOLDER ||
          ''
        }
        width={72}
        priority
        height={72}
        className='h-full max-h-[72px] w-[72px] object-cover'
      />
      <div className='ml-[24px]	flex-1 pr-[24px] text-fieldInput text-dark-400'>
        <p className='clamp-2'>{name}</p>
        <p className='font-bold text-red-500'>{price}</p>
      </div>
      {canCompare && (
        <button
          onClick={handleAddToCompare}
          className=' r-[10px] relative flex w-[110px] items-center'
        >
          <CompareAddIcon className='mr-[5px]'></CompareAddIcon>
          <span className='text-blue'>So sánh</span>
        </button>
      )}
    </div>
  )
}
