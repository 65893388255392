import { MenuType, PageTypeSuffix } from '@/constants'
import { useNotification } from '@/containers'
import { type Menu } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { IconPromotion } from '../Icon/HeaderIcon'
import ImageOptimize from '../ImageOptimize'

interface QuickMenuProps {
  QuickMenuData: Menu[]
  translateText: {
    allCate: string
    promotion: string
  }
}

export const QuickMenu = ({ QuickMenuData, translateText }: QuickMenuProps) => {
  const { asPath } = useRouter()
  const { systemConfig } = useNotification()
  const checkActive = (item: string) => {
    if (asPath == item) {
      return 'text-blue'
    }
  }
  return (
    <div className='flex justify-end'>
      <div className={`relative`} id='menuBoxScroll'>
        <div className='no-scrollbar relative z-10 flex h-full w-full max-w-[840px] items-center gap-x-[24px] overflow-auto whitespace-nowrap px-[24px] text-dark-900'>
          {QuickMenuData &&
            QuickMenuData.map((item, index) => {
              return (
                <Link
                  href={
                    item.type === MenuType.PRODUCT_CAT
                      ? `/${item.slug + PageTypeSuffix.CATEGORY}`
                      : item.url || '/'
                  }
                  key={index}
                  className={`flex gap-1.5 text-base font-normal hover:text-blue-400 ${checkActive(
                    '/' + item.slug + PageTypeSuffix.CATEGORY
                  )}`}
                >
                  {item.image && (
                    <Image
                      width={24}
                      height={24}
                      alt={getTranslate(item.name) || ''}
                      src={item.image}
                      className='h-4 w-4'
                      priority
                    />
                  )}
                  <span>{getTranslate(item.name)}</span>
                </Link>
              )
            })}
        </div>
      </div>
      <div className='border-black'>
        <Link
          className='max-w flex h-7 cursor-pointer items-center overflow-hidden rounded'
          href='/hot-deal'
        >
          {systemConfig?.promotionImage ? (
            <ImageOptimize
              width={160}
              height={32}
              alt={'Khuyến mãi'}
              src={systemConfig?.promotionImage || ''}
              className='h-full w-auto'
            />
          ) : (
            <div className='flex items-center justify-between bg-gradient-to-r from-yellow-300 to-yellow-200 px-1 py-[4px]'>
              <IconPromotion />
              <span className='ml-[5px] text-Headline font-bold text-dark-400'>
                {translateText.promotion}
              </span>
            </div>
          )}
        </Link>
      </div>
    </div>
  )
}
