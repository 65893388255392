import { type Menu as MenuType } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Image from 'next/image'
import Link from 'next/link'
import Menu, { MenuItem, SubMenu } from 'rc-menu'
import 'rc-menu/assets/index.css'
import { useEffect, useRef, useState } from 'react'
import ImageOptimize from '../ImageOptimize'

const DEFAULT_HIGHLIGHT = 0
interface CateDropdownProps {
  items: MenuType[]
  showIcon?: boolean
  className?: string
  popupClassName?: string
}
const CateDropdown = ({
  items,
  className,
  popupClassName,
}: CateDropdownProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [positionMG, setPositionMG] = useState(false)
  const [isScrolled, setIsScrolled] = useState<number>(225)
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    const el = document.querySelector('.megaMenu')
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setPositionMG(true)
        } else {
          setPositionMG(false)
        }
      },
      { threshold: [1] }
    )

    observer.observe(el as HTMLElement)
  }
  useEffect(() => {
    // const el = document.querySelector('.megaMenu')
    const el = ref.current
    const handleScroll = () => {
      if (el) {
        const rect = el.getBoundingClientRect()
        setIsScrolled(rect.top)
      } else {
        setIsScrolled(225)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`${className}`} ref={ref}>
      <Menu triggerSubMenuAction='hover' className='min-h-[450px]' multiple>
        {items &&
          items.map((e, index) => (
            <SubMenu
              popupClassName={`pl-1 !fixed z-[999] subMenuCS ${
                isScrolled > 66 ? 'transition-none topcs-' + isScrolled : ''
              } ${positionMG ? 'top-67' : popupClassName}`}
              title={
                <div
                  key={index + e.menuType}
                  className='relative flex items-center gap-x-[13px] px-[18px] py-[12px]'
                >
                  <div className='min-w-[24px]'>
                    {e.image ? (
                      <div className='absolute left-[-8px] top-[50%] translate-y-[-50%]'>
                        <ImageOptimize
                          src={e.image}
                          width={54}
                          height={34}
                          alt={getTranslate(e.name)}
                          priority
                        />
                      </div>
                    ) : (
                      <ImageOptimize
                        src={e.icon}
                        width={24}
                        height={24}
                        alt={getTranslate(e.name)}
                        priority
                      />
                    )}
                  </div>
                  <div className='flex w-full whitespace-nowrap'>
                    <div className=''>
                      <Link
                        href={e.url ? e.url : '/'}
                        className={`${
                          index === DEFAULT_HIGHLIGHT
                            ? 'text-red-500'
                            : 'text-black-400'
                        } cursor-pointer text-Headline font-bold  text-black-400 transition-all ease-in hover:text-blue-400`}
                      >
                        {getTranslate(e.name)}
                      </Link>
                      <div className='w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-mainBody text-black-400'>
                        {e.children &&
                          e.children
                            .filter(e => e.highlight)
                            .map((f, index) => (
                              <Link href={f.url || '/'} key={'lv-1-' + index}>
                                <span
                                  key={index}
                                  className=' cursor-pointer hover:text-blue-400'
                                >
                                  {index > 0 && '  -  '}
                                  {getTranslate(f?.name)}
                                </span>
                              </Link>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              }
              key={index}
            >
              <MenuItem
                className='!z-[9999] min-h-[450px] w-[928px] !p-[20px] !pr-[150px]'
                key={'lv-1-' + index}
              >
                <div className='grid grid-cols-4 gap-x-[100px] gap-y-[60px]'>
                  {e.children &&
                    e.children.map((r, ind) => (
                      <div key={ind} className='flex-33'>
                        <Link href={r.url ? r.url : '/'} key={'lv-2-' + ind}>
                          <span className='flex gap-1.5 text-base font-bold uppercase text-black-400'>
                            {getTranslate(r?.name)}
                            {r.image && (
                              <Image
                                width={24}
                                height={24}
                                alt={getTranslate(r.name) || ''}
                                src={r.image}
                                className='h-4 w-4'
                                priority
                              />
                            )}
                          </span>
                        </Link>
                        <div className='flex flex-col'>
                          {r.children &&
                            r.children.map((f, fIndex: number) => (
                              <Link
                                href={f.url ? f.url : '/'}
                                key={'lv-3-' + fIndex}
                              >
                                <span className='cursor-pointer text-mainBody font-normal !leading-[20px] text-black-400 hover:text-blue-400'>
                                  {getTranslate(f.name)}
                                </span>
                              </Link>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </MenuItem>
            </SubMenu>
          ))}
      </Menu>
    </div>
  )
}
export default CateDropdown
