import { type Menu, type TopHeaderSlider } from '@/types'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import CateDropdown from '../CateDropdown'
import { HeaderMenu } from '../Icon/HeaderMenu'
import { QuickMenu } from '../QuickMenu'

interface MegaMenuProps {
  placeholder?: string
  className?: string
  translateText: {
    allCate: string
    promotion: string
  }
  isError?: boolean | string
  id?: string
  name?: string
  children?: JSX.Element[] | JSX.Element
  alert?: boolean
  value?: string
  icon?: JSX.Element
  megaMenuData: Menu[]
  quickMenuData: Menu[]
  topBanner: TopHeaderSlider[]
}

export const MegaMenu = ({
  translateText,
  megaMenuData,
  quickMenuData,
  topBanner,
}: MegaMenuProps) => {
  const router = useRouter()
  const [showAllCate, setShowAllCate] = useState(false)

  const cateDropdown = useMemo(() => {
    return (
      <CateDropdown
        popupClassName={`top-${topBanner.length > 0 ? 225 : 158}`}
        className={`${
          showAllCate ? 'block ' : 'hidden'
        } absolute top-[66px] z-[9999] min-h-[450px] w-fit max-w-[318px] rounded-[8px] bg-white-400 shadow-grey-400 drop-shadow`}
        items={megaMenuData}
      />
    )
  }, [megaMenuData, showAllCate, topBanner, router])
  const renderQuickMenu = useMemo(() => {
    return (
      <QuickMenu translateText={translateText} QuickMenuData={quickMenuData} />
    )
  }, [quickMenuData])

  useEffect(() => {
    const handleStart = () => {
      setShowAllCate(false)
    }
    router.events.on('routeChangeComplete', handleStart)
    return () => {
      router.events.off('routeChangeComplete', handleStart)
    }
  }, [router])

  return (
    <div className='headerMenu mx-auto flex h-[66px] w-full items-center justify-between'>
      <div
        className='flex-355 relative flex h-full max-w-[355px]'
        onMouseEnter={() => setShowAllCate(true)}
        onMouseLeave={() => setShowAllCate(false)}
        onClick={() => setShowAllCate(true)}
      >
        <div className='flex flex-1 cursor-pointer items-center justify-start'>
          <HeaderMenu />
          <span className=' ml-[12px] text-Headline font-normal text-dark-400'>
            {translateText.allCate}
          </span>
        </div>

        {showAllCate && cateDropdown}
      </div>
      {renderQuickMenu}
    </div>
  )
}
