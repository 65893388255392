import ImageOptimize from '@/components/ImageOptimize'
import { PageTypeSuffix } from '@/constants'

import { useNotification, useStore } from '@/containers'
import { getTranslate } from '@/utils/api-interceptors'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid'

const ChooseProductCompare = dynamic(
  () => import('../components/Popup/ChooseProductCompare'),
  {
    ssr: false,
  }
)

const ComparePopup = dynamic(() => import('../components/Popup/ComparePopup'), {
  ssr: false,
})

const CompareTinyPopup = dynamic(
  () => import('../components/Popup/CompareTinyPopup'),
  {
    ssr: false,
  }
)

const OnDevelopPopup = dynamic(
  () => import('../components/Popup/OnDevelopPopup'),
  {
    ssr: false,
  }
)
type FooterProps = {
  page?: string
}
const Footer = ({ page }: FooterProps) => {
  const {
    bottomMenu,
    isOpenComingSoon,
    setIsOpenComingSoon,
    isOpenAddComparePopup,
    setProductCompares,
    productCompares,
    setIsOpenAddComparePopup,
    isOpenComparePopup,
    setIsOpenComparePopup,
    productViewed,
    catViewId,
  } = useStore()
  const { systemConfig } = useNotification()
  // const [numberOfCenter, setNumberOfCenter] = useState()

  // useEffect(() => {
  //   const getListStore = async () => {
  //     const res = await api(`api/store`, {
  //       method: 'GET',
  //       baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
  //     })

  //     if (res && !(res instanceof Error)) {
  //       setNumberOfCenter(res?.data?.length)
  //       return
  //     }
  //   }
  //   getListStore()
  // }, [])

  const t = useTranslations('Footer')
  return (
    <footer id='footer' className={`mx-auto w-full`}>
      {page === 'product-detail' && (
        <>
          <ComparePopup
            open={isOpenComparePopup}
            setIsOpenComparePopup={setIsOpenComparePopup}
            setProductCompares={setProductCompares}
            setIsOpenAddComparePopup={setIsOpenAddComparePopup}
            productCompares={productCompares}
          />

          <ChooseProductCompare
            catViewId={catViewId}
            productViewed={productViewed}
            isOpen={isOpenAddComparePopup}
            setProductCompares={setProductCompares}
            productCompares={productCompares}
            setIsOpen={setIsOpenAddComparePopup}
          ></ChooseProductCompare>

          <CompareTinyPopup
            isOpen={!isOpenComparePopup}
            productCompares={productCompares.filter(e => e._id).length}
            setIsOpen={setIsOpenComparePopup}
          ></CompareTinyPopup>
        </>
      )}

      <OnDevelopPopup
        isOpen={isOpenComingSoon}
        setIsOpen={setIsOpenComingSoon}
      />

      <section className={` bg-neutral-100 pb-[60px] pt-[45px] `}>
        <div className={`container mx-auto flex justify-start`}>
          {bottomMenu &&
            bottomMenu.length > 0 &&
            bottomMenu.map(e => (
              <div className='max-w-[25%] flex-4' key={uuidv4()}>
                <h5 className='mb-3 text-fieldLabel font-bold text-dark-400'>
                  {getTranslate(e.name)}
                </h5>
                {e.children && e.children.length > 0 && (
                  <ul>
                    {e.children.map(f => (
                      <li key={uuidv4()} className='mb-[4px] flex items-start'>
                        {f.type === 'link' ? (
                          <a href={`${f.url}` || ''}>{getTranslate(f.name)}</a>
                        ) : (
                          <a
                            href={`/${f.slug + PageTypeSuffix.PAGE_CMS}` || ''}
                          >
                            {getTranslate(f.name)}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </section>
      <section className='border-t-[1px] border-neutral-100 py-2'>
        <div className={`container mx-auto flex items-center justify-between`}>
          <ul className=' flex gap-x-5 text-base font-normal leading-tight text-gray-800'>
            <li>
              <Link
                href={'/'}
                className='relative pr-5 after:absolute after:right-0 after:top-0 after:block after:h-full after:w-[1px] after:bg-zinc-300 after:content-[""]'
              >
                Trang chủ
              </Link>
            </li>
            <li>
              <Link
                href={'/hot-deal'}
                className='relative pr-5 after:absolute after:right-0 after:top-0 after:block after:h-full after:w-[1px] after:bg-zinc-300 after:content-[""]'
              >
                Khuyến mãi
              </Link>
            </li>
            <li>
              <Link href={'/post'} className='relative'>
                Tin tức & sự kiện
              </Link>
            </li>
          </ul>

          <div className=''>
            <Link
              href={'/store'}
              className='flex h-[40px] w-max items-center justify-center gap-1 rounded border-[1px] border-grey-900 px-2 text-mainBody text-dark-500'
            >
              <span className='text-Label text-dark-500'>
                Hệ thống siêu thị
              </span>
              <img
                src='/assets/icons/arrow/arrow-right-long.svg'
                alt='Xem thêm'
              />
            </Link>
          </div>
        </div>
      </section>
      <section
        className={`footerInfo border-t-[1px] border-neutral-100 pb-3 pt-4`}
      >
        <div className={`container mx-auto flex items-end justify-between`}>
          <div className='max-w-[70%] flex-[0_0_70%]'>
            <div className='aboutUs mb-3 flex content-end items-end gap-2'>
              <span className='mb-[3px] text-base font-bold leading-tight text-gray-800'>
                THÔNG TIN VỀ
              </span>
              <ImageOptimize
                src={systemConfig?.companyLogo || ''}
                alt={getTranslate(systemConfig?.companyName) || ''}
                className='h-auto w-auto max-w-[56px]'
              />
            </div>
            <div className='hotLine mb-3 grid grid-cols-2 items-center gap-1'>
              {systemConfig?.companyPhone && (
                <div className='w-full'>
                  <p className='text-2xl font-bold leading-tight text-dark-900'>
                    {systemConfig?.companyPhone}
                  </p>
                  <p className='text-sm italic text-gray-800'>
                    {systemConfig?.companyLabelPhone}
                  </p>
                </div>
              )}
              {systemConfig?.companyPhone2 && (
                <div className='w-full'>
                  <p className='text-2xl font-bold leading-tight text-dark-900'>
                    {systemConfig?.companyPhone2}
                  </p>
                  <p className='text-sm italic text-gray-800'>
                    {systemConfig?.companyLabelPhone2}
                  </p>
                </div>
              )}
            </div>
            <ul className='text-sm text-gray-800 '>
              <li>
                <span>Website: </span>
                <span>{systemConfig?.companyWebsite}</span>
              </li>
              <li>
                <span>Email: </span>
                <span>{systemConfig?.companyEmail}</span>
              </li>
            </ul>
          </div>

          <div className='max-w-[30%] flex-[0_0_30%] text-right'>
            <div className='flex flex-col flex-wrap justify-end'>
              <ul className='mb-0 flex items-center justify-end'>
                <li className='ml-[16px]'>
                  <Link
                    href={systemConfig?.companyFacebook || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-grey-400'
                  >
                    <img
                      src='/assets/icons/footer/fb.svg'
                      alt='Facebook'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
                <li className='ml-[16px]'>
                  <Link
                    href={systemConfig?.companyInstagram || '/'}
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-grey-400'
                    target='blank'
                  >
                    <img
                      src='/assets/icons/footer/instagram.svg'
                      alt='Instagram'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
                <li className='ml-[16px]'>
                  <Link
                    href={systemConfig?.companyTikTok || '/'}
                    target='blank'
                    className='flex h-[36px] w-[36px] items-center justify-center rounded-full border border-grey-400'
                  >
                    <img
                      src='/assets/icons/footer/tiktok.svg'
                      alt='Tiktok'
                      className='h-[20px] w-[20px]'
                    />
                  </Link>
                </li>
              </ul>
              {systemConfig?.announcedMOIT && systemConfig?.companyVerify && (
                <Link
                  href={systemConfig?.companyVerify || ''}
                  title='Đã thông báo'
                  target='blank'
                  className='ml-auto mt-1 block'
                >
                  <ImageOptimize
                    width={170}
                    height={58}
                    src={systemConfig?.announcedMOIT || ''}
                    className='ml-auto h-auto'
                    alt='Đã thông báo'
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        className={`footerInfo border-t-[1px] border-neutral-100 pb-5 pt-2`}
      >
        <div className={`container mx-auto flex items-end justify-between`}>
          <div className='max-w-[50%] flex-50'>
            <ul className='text-base leading-relaxed'>
              <li>
                <p className=' font-bold text-dark-400'>
                  {getTranslate(systemConfig?.companyName)}
                </p>
              </li>
              <li>
                <span>{t('businessRegisterCertificate')}: </span>
                <span>{getTranslate(systemConfig?.businessLicense)}</span>
              </li>
              <li>
                <span>{t('address')}: </span>
                <span>{getTranslate(systemConfig?.companyAddress)}</span>
              </li>
            </ul>
          </div>
          <div className='copyright max-w-[50%] flex-50 text-right'>
            <p className='mb-1.5 text-sm font-normal italic leading-tight text-gray-800'>
              {getTranslate(systemConfig?.companyLicense)}
            </p>
          </div>
        </div>
      </section>
    </footer>
  )
}

export { Footer }
